@import "src/variables";

.Spinner {
  margin-top: 3rem;
  align-items: center;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;

  :global(.spinner-border) {
    color: $depressed-header-blue;
  }
}
